import * as React from 'react';
import SingleColumnLayout from '../layouts/singleColumnLayout/SingleColumnLayout';
import ExternalLink from '../components/index/ExternalLink';
import Image from '../components/index/Image';
import Grid from '../components/soul/layout/grid/Grid';
import GridItem from '../components/soul/layout/grid/GridItem';
import {GridItemSize} from '../components/soul/layout/grid/GridItemSize';
import {graphql, Link} from 'gatsby';
import {FluidObject} from 'gatsby-image';
import {GridDensity} from '../components/soul/layout/grid/GridDensity';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface IndexPageProps {
    data: {
        file: {
            childIndexJson: {
                images: {
                    childImageSharp: {
                        fluid: FluidObject
                    }
                }[]
            }
        }
    };
}

const IndexPage: React.FC<IndexPageProps> = (props) => {
    return (
        <SingleColumnLayout>
            <div className="centered-column">
                <div className="soul-content--m">
                    <h1 className="page-title">
                        Join the discussion with digital engineering experts from the Aerospace and Defense industry.
                    </h1>
                </div>
                <Grid density={GridDensity.WIDE} mobileInverse={false} additionalClasses="l-grid--middle soul-space-stack-top-xs">
                    <GridItem size={GridItemSize.ONE_HALF} additionalClasses="soul-content--m l-tab--one-whole l-palm--one-whole h-full-height soul-space-stack-bottom-xl">
                        <p className="soul-space-stack-top-xs">
                            The fourth edition of the ESTECO A&D Technology Days is a free in-person only event taking place on March 14-15, 2023, at the Pima
                            Air & Space Museum in Tucson, AZ.
                        </p>
                        <p className="soul-space-stack-top-xs">
                            The event will focus on digital engineering capability in the Aerospace & Defense industry, and in particular Multidisciplinary
                            Design Analysis & Optimization (MDAO) and Simulation Process Data Management (SPDM).
                        </p>
                        <p className="soul-space-stack-top-xs">
                            <strong>Join us in Tucson and learn more about our VOLTA platform from digital engineering experts from Lockheed Martin and Raytheon Missiles & Defense.
                                Our guest speaker Colonel Richard B. Bushong, a 99 year old WW2 B-17 pilot who flew 28 missions in ETO, will share his experiences and celebrate his 100th birthday with us!</strong>
                        </p>
                    </GridItem>
                    <GridItem size={GridItemSize.ONE_HALF} additionalClasses="soul-content--m l-tab--one-whole l-palm--one-whole h-full-height">
                        <iframe width="100%" height="250rem" src="https://www.youtube.com/embed/XQAmxXCCpcM" title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>
                    </GridItem>
                </Grid>

                <Grid density={GridDensity.WIDE} mobileInverse={false} additionalClasses="l-grid--middle soul-space-stack-top-l h-responsive-row">
                    <GridItem size={GridItemSize.ONE_HALF} additionalClasses="l-palm--one-whole h-full-height soul-space-stack-bottom-xl">
                        <div className="h-full-height soul-content--m">
                            <h2 className="soul-font-size-2-xl h-text-bold soul-space-stack-bottom-s">Day One: 14 March</h2>
                            <p>Topics include MDAO, SPDM, Digital Thread, our roadmap and R&D activities, and presentations from customers.
                                We will discuss how VOLTA can be used to scale up and democratize MDAO capabilities across the enterprise.</p>
                        </div>
                    </GridItem>
                    <GridItem size={GridItemSize.ONE_HALF} additionalClasses="l-palm--one-whole h-full-height">
                        <div className="h-full-height soul-content--m">
                            <h2 className="soul-font-size-2-xl h-text-bold soul-space-stack-bottom-s">Day Two: 15 March</h2>
                            <p>We will offer a workshop where you can experience first-hand how VOLTA fosters a true, real time collaborative design environment
                                for solving multidisciplinary design problems.</p>
                        </div>
                    </GridItem>
                </Grid>

                <Grid density={GridDensity.WIDE} mobileInverse={false} additionalClasses="l-grid--middle soul-space-stack-both-2-xl">
                    <GridItem size={GridItemSize.ONE_HALF} additionalClasses="l-tab--one-whole l-palm--one-whole">
                        <div className="soul-content--m">
                            <h2 className="soul-font-size-2-xl h-text-bold soul-space-stack-bottom-s">The venue</h2>
                            <p className=" soul-space-stack-bottom-l">A wonderland for aviation enthusiasts, the Pima Air & Space Museum, located in Tucson,
                                Arizona, is one of the world’s largest non-government funded aerospace museums. It features a display of nearly 300 aircraft
                                spread out over 80 acres on a campus occupying 127 acres.</p>
                        </div>
                        <div className="h-text-align-right soul-space-stack-bottom-l">
                            <Link to="https://pimaair.org/"
                                  className="c-link">
                                Find out more
                                <span className="a-icon a-icon--inline a-icon--m a-icon--forward"></span>
                            </Link>
                        </div>
                    </GridItem>
                    <GridItem size={GridItemSize.ONE_HALF} additionalClasses="l-tab--one-whole l-palm--one-whole">
                        <div className="full-width inner-full-width" dangerouslySetInnerHTML={{
                            __html: `
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d54054.720367677684!2d-110.868703!3d32.138962!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8e49b10f9b327453!2sPima%20Air%20%26%20Space%20Museum!5e0!3m2!1sen!2sus!4v1674115549736!5m2!1sen!2sus" width="600" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        `
                        }}></div>
                    </GridItem>
                </Grid>
            </div>
        </SingleColumnLayout>
    );
};

export const pageQuery = graphql`
    query image {
        file(name: {eq: "tech-days-content"}) {
            childIndexJson {
                images {
                    childImageSharp {
                        fluid(quality: 100, sizes: "(max-width: 1024px) 100vw, 1024px") {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;
export default IndexPage;
